import { useEffect, useState } from 'react';
import { SQLiteService } from './db.content.ts';
import { Tables } from './types.content.ts';

interface WalkthroughList {
    title: string;
    id: number;
    loop: boolean;
}

interface WalkthroughContent {
    title: string;
    id: number;
    seconds: number;
    type: 'breathe' | 'video';
    source: string;
    thumbnail: string;
}

interface WalkthroughListWithContent extends WalkthroughList {
    content: WalkthroughContent[];
}

async function getWalkthroughListById(
    id: number
): Promise<WalkthroughList | null> {
    const dbService = await SQLiteService.getInstance();
    const walkthroughList = dbService.query(
        `SELECT * FROM ${Tables.walkthrough_lists} WHERE id = ?`,
        [id]
    )[0] as WalkthroughList;
    return walkthroughList;
}

async function getWalkthroughListContentById(
    id: number
): Promise<WalkthroughContent[] | null> {
    const dbService = await SQLiteService.getInstance();

    const walkthroughContent = (await dbService.query(
        `
        SELECT 
    cw.seconds ,
    cw.id ,
    wc.title,
    wc.type ,
    wc.thumbnail,
    wc.source
FROM 
    walkthrough_lists wl
LEFT JOIN 
    walkthrough_lists_components wlc 
    ON wl.id = wlc.entity_id
LEFT JOIN 
    components_walkthrough_list_content_walkthrough_list_contents cw 
    ON wlc.component_id = cw.id
LEFT JOIN 
    components_walkthrough_list_content_walkthrough_list_contents_walkthrough_content_links link 
    ON cw.id = link.walkthrough_list_content_id
LEFT JOIN 
    walkthrough_contents wc 
    ON link.walkthrough_content_id = wc.id
WHERE 
    wl.id = ?
ORDER BY
    wlc."order";`,
        [id]
    )) as WalkthroughContent[];

    return walkthroughContent;
}

export function useGetWalkthroughListById(id: number): {
    loading: boolean;
    error?: string;
    data: WalkthroughListWithContent;
} {
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<WalkthroughListWithContent>({
        id: -1,
        title: '',
        content: [],
        loop: false,
    });

    useEffect(() => {
        Promise.all([
            getWalkthroughListById(id),
            getWalkthroughListContentById(id),
        ])
            .then(([listData, listContent]) => {
                if (listContent && listData) {
                    setData({
                        ...listData,
                        content: listContent,
                    });
                }
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setError('Failed');
            });
    }, []);

    return { error, loading, data };
}
