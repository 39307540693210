import React, { PropsWithChildren } from 'react';

interface PageNotificationProps extends PropsWithChildren {
    notificationColor: string;
    icon?: React.ReactNode;
    mainText?: string;
    secondaryText?: string;
    fontColor?: string;
}

export function PageNotification(props: PageNotificationProps) {
    const {
        notificationColor,
        mainText,
        icon,
        secondaryText,
        fontColor,
        children,
    } = props;
    return (
        <div
            className="rounded-lg p-4 flex w-full "
            style={{ backgroundColor: notificationColor }}
        >
            {icon && (
                <div className="mr-4">
                    <div
                        className="  h-12 w-12 rounded-full flex items-center justify-center "
                        style={{ backgroundColor: '#ffffff59' }}
                    >
                        {icon}
                    </div>
                </div>
            )}

            <div style={{ color: fontColor || '#fff' }}>
                {mainText && (
                    <span className="font-bold text-xl">
                        {mainText}
                        <br />
                    </span>
                )}
                {secondaryText && (
                    <span>
                        {secondaryText}
                        <br />
                    </span>
                )}
                {children}
            </div>
        </div>
    );
}
