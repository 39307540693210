import React, { ReactNode } from 'react';
import { Button } from '../../../components/common.tsx';

export interface OnboardTemplatePageProps {
    header: string;
    description: string;
    buttonLabel?: string;
    counter: ReactNode;
    // onBack: () => void;
    onNext: () => void;
    imageSrc: string;
}

function OnboardTemplatePage(props: OnboardTemplatePageProps) {
    const {
        description,
        header,
        imageSrc,
        // onBack,
        onNext,
        counter,
        buttonLabel,
    } = props;

    return (
        <div className="h-full min-w-full">
            <div className="h-1/2 bg-primary-200 w-full">
                <img
                    src={imageSrc}
                    alt={header}
                    style={{ maxHeight: '80%' }}
                    className="w-auto  m-auto"
                />
            </div>
            <div className="p-8 flex h-1/2 flex-col justify-between">
                <div>
                    <h1 className="text-primary text-5xl pb-4">{header}</h1>
                    <p className="text-xl">{description}</p>
                </div>

                <div className="flex justify-between items-center">
                    <div>{counter}</div>
                    <div className="w-4/12">
                        <Button onClick={onNext}>
                            {buttonLabel || 'Next'}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OnboardTemplatePage;
