import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { routeNames } from './types.navigation.ts';
import { useAuth } from '../contexts/AuthContext.tsx';

function AuthZone() {
    const { user, userData } = useAuth();
    if (
        userData &&
        window.location.pathname !== routeNames.onboard &&
        !userData?.isOnboarded
    ) {
        return <Navigate to={routeNames.onboard} />;
    }

    if (
        window.location.pathname === routeNames.onboard &&
        userData?.isOnboarded
    ) {
        return <Navigate to={routeNames.nook} />;
    }
    return user ? <Outlet /> : <Navigate to={routeNames.login} />;
}

export default AuthZone;
