import React, { PropsWithChildren } from 'react';
import {
    createBrowserRouter,
    Route,
    createRoutesFromElements,
    Navigate,
    Link,
} from 'react-router-dom';
import { routeNames } from './types.navigation.ts';
import LoginPage from '../pages/unauthenticated/LoginPage.tsx';
import NookPage from '../pages/authenticated/NookPage.tsx';
import AuthZone from './AuthZone.tsx';
import SignupPage from '../pages/unauthenticated/SignupPage.tsx';
import ExplorePage from '../pages/authenticated/ExplorePage.tsx';
import CategoryPage from '../pages/authenticated/CategoryPage.tsx';
import PlayPage from '../pages/authenticated/PlayPage.tsx';
import PageLayout from '../components/PageLayout.tsx';
import AccountPage from '../pages/authenticated/AccountPage.tsx';
import SettingsPage from '../pages/authenticated/SettingsPage/SettingsPage.tsx';
import SubscribeProtectedRoute from '../payments/components/SubscribeProtectedRoute.tsx';
import SubscriptionPage from '../payments/pages/SubscriptionPage.tsx';
import ForgotPasswordPage from '../pages/unauthenticated/ForgotPasswordPage.tsx';
import { useAuth } from '../contexts/AuthContext.tsx';
import Page404 from '../pages/Page404.tsx';
import ContactPage from '../pages/authenticated/contactPage.tsx';
import SelectedInterestsPage from '../pages/authenticated/SelectInterestsPage.tsx';
import FavouritePage from '../pages/authenticated/FavouritePage.tsx';
import { Button } from '../components/common.tsx';
import HistoryPage from '../pages/authenticated/HistoryPage.tsx';
import { WalkThroughPage } from '../pages/authenticated/WalkthoughPage.tsx';
import OnboardingShell from '../pages/authenticated/Onboarding/OnboardingShell.tsx';
import OfferCompletePage from '../pages/authenticated/OfferCompletePage/OfferCompletePage.tsx';

function RootLoader() {
    const { user } = useAuth();

    if (user) {
        return <Navigate to={routeNames.nook} />;
    }
    return <Navigate to={routeNames.signup} />;
}

function NoAuthRouteLoader(props: PropsWithChildren) {
    const { user } = useAuth();

    if (user) {
        return <Navigate to={routeNames.nook} />;
    }
    const { children } = props;
    return children;
}

function AuthRouteLoader(props: PropsWithChildren) {
    const { user } = useAuth();

    if (!user) {
        return <Navigate to={routeNames.login} />;
    }
    const { children } = props;
    return children;
}

function ErrorElement() {
    return (
        <div className="flex items-center justify-center text-center h-full">
            <div className="w-11/12 max-w-lg ">
                <img
                    src="/images/blob_142.png"
                    alt="catus"
                    className="max-w-48 mx-auto"
                />
                <h1 className="mb-2 text-5xl">Oops Something went wrong!</h1>
                <p className="mb-2 text-lg leading-5">
                    We are in early access this might happen from time to time,
                    please have patience while we fix it
                </p>
                <div className="mb-2">
                    <Link to="..">
                        <Button>Go Back</Button>
                    </Link>
                </div>
                <Link to={routeNames.account_contact}>
                    <Button>Report a problem</Button>
                </Link>
            </div>
        </div>
    );
}

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            path="/"
            element={<PageLayout />}
            errorElement={<ErrorElement />}
        >
            <Route index element={<RootLoader />} />

            <Route element={<SubscribeProtectedRoute />}>
                <Route element={<AuthZone />}>
                    <Route
                        path={routeNames.onboard}
                        element={
                            <AuthRouteLoader>
                                <OnboardingShell />
                            </AuthRouteLoader>
                        }
                    />
                    <Route
                        path={routeNames.subscription}
                        element={<SubscriptionPage />}
                    />

                    <Route path={routeNames.nook} element={<NookPage />} />
                    <Route
                        path={routeNames.favourite}
                        element={<FavouritePage />}
                    />

                    <Route
                        path={routeNames.history}
                        element={<HistoryPage />}
                    />
                    <Route
                        path={routeNames.offer_complete}
                        element={<OfferCompletePage />}
                    />

                    <Route path={routeNames.account}>
                        <Route index element={<AccountPage />} />
                        <Route
                            path={routeNames.settings}
                            element={<SettingsPage />}
                        />
                        <Route
                            path={routeNames.interests}
                            element={<SelectedInterestsPage />}
                        />
                        <Route
                            path={routeNames.contact}
                            element={<ContactPage />}
                        />
                    </Route>

                    <Route path={routeNames.explore}>
                        <Route index element={<ExplorePage />} />
                        <Route path={routeNames.category}>
                            <Route path=":id" element={<CategoryPage />} />
                        </Route>
                    </Route>

                    <Route path={routeNames.play}>
                        <Route path=":id" element={<PlayPage />} />
                    </Route>

                    <Route path={routeNames.walkthrough}>
                        <Route path=":id" element={<WalkThroughPage />} />
                    </Route>
                </Route>
            </Route>

            {/* Unauthenticated routes */}
            <Route
                path={routeNames.login}
                element={
                    <NoAuthRouteLoader>
                        <LoginPage />
                    </NoAuthRouteLoader>
                }
            />
            <Route
                path={routeNames.signup}
                element={
                    <NoAuthRouteLoader>
                        <SignupPage />
                    </NoAuthRouteLoader>
                }
            />
            <Route path={routeNames.forgot} element={<ForgotPasswordPage />} />

            {/* Authenticated routes */}

            <Route path="*" element={<Page404 />} />
        </Route>
    )
);

export default router;
