/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-use-before-define */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { InfoCircleOutlined, LeftCircleFilled } from '@ant-design/icons';
import { Button } from '../../components/common.tsx';
import { useGetWalkthroughListById } from '../../content/walkthrough.sql.ts';
import LoadingOverlay from '../../components/LoadingOverlay.tsx';


interface WalkthroughTileProps {
    title: string
    seconds: string
    isHighlighted: boolean
    onClick?: () => void
}

function WalkthroughTile(props: WalkthroughTileProps) {
    const { seconds, title, isHighlighted, onClick } = props
    return <button className={`rounded-md w-full p-4 border-2 text-left ${isHighlighted && 'border-button-blue '}`} onClick={onClick}>
        <p>{title}</p>
        <p>{seconds}</p>
    </button>
}

let intervalInstance: false | NodeJS.Timer;

export function WalkThroughPage() {
    const { id = '' } = useParams<{ id: string }>();
    const idAsInt = parseInt(id, 10)
    if (Number.isNaN(idAsInt)) {
        Error('Content Doesnt Exist')
    }
    const { data, loading } = useGetWalkthroughListById(parseInt(id, 10))
    const [currentIndex, setCurrentIndex] = useState(0)
    const [countdown, setCountdown] = useState(4);
    const [totalCount, setTotalCount] = useState(0)
    const [playFlag, setPlayFlag] = useState(false)
    const navigate = useNavigate()

    const videoPlayerRef = useRef<HTMLVideoElement>(null)

    useEffect(() => {
        const { content } = data
        if (content.length > 0) {
            setCountdown(data.content[0].seconds)
        }
    }, [data])

    useEffect(() => {
        if (currentContent && currentContent.type === 'video') {
            videoPlayerRef.current?.play()
        }
        if (countdown === 0) {

            if (currentIndex < data.content.length - 1) {
                setCountdown(data.content[currentIndex + 1].seconds)
                setCurrentIndex(currentIndex + 1)
            }
            else if (data.loop) {
                setCountdown(data.content[0].seconds)
                setCurrentIndex(0)
                setTotalCount(totalCount + 1)
            }
            else if (intervalInstance) {
                setPlayFlag(false)
                clearInterval(intervalInstance)
            }
        }
    }, [countdown])

    useEffect(() => {

        if (playFlag) {
            intervalInstance = setInterval(() => {
                setCountdown((prev) => {
                    return prev - 1;
                })
            }, 1000);
        }
        // Calculate the fill percentage dynamically
        return () => {
            if (intervalInstance) {
                clearInterval(intervalInstance);
            }
        };
    }, [countdown, playFlag]);

    const currentContent = useMemo(() => data.content[currentIndex], [currentIndex, data])

    const onSelectContent = (index: number) => {
        setCountdown(data.content[index].seconds)
        setCurrentIndex(index)
    }

    function onTogglePlayer(currentPlayState: boolean) {
        if (currentPlayState) {
            setPlayFlag(false)
            if (currentContent.type === 'video') {
                videoPlayerRef.current?.pause()
            }
            if (currentContent.type === 'breathe') {
                setCountdown(currentContent.seconds)
            }

        }
        else {
            setPlayFlag(true)
            if (currentContent.type === 'video') {
                videoPlayerRef.current?.play()
            }
            if (currentContent.type === 'breathe') {
                setCountdown(currentContent.seconds)
            }
        }
    }





    if (loading) {
        return <LoadingOverlay isLoading />
    }


    return (
        <div>
            <div className='bg-blue-100'>
                <div className='bg-white flex justify-between items-center '>
                    <Link to=".."
                        className='text-2xl p-4'

                        onClick={(e) => {
                            e.preventDefault();
                            navigate(-1);
                        }}><LeftCircleFilled size={10} /></Link>
                    <h1 className="text-center  py-4 text-lg">
                        {data.title}
                    </h1>
                    <Link to=".."
                        className='text-2xl p-4'
                        onClick={(e) => {
                            e.preventDefault();
                        }}><InfoCircleOutlined /></Link>
                </div>


                {currentContent?.type === 'breathe' &&
                    <div className="relative w-full py-5">
                        {/* Outer circle */}
                        <div className='m-auto w-fit'>
                            <CircularTimer speedString={playFlag ? `${currentContent?.seconds}_${Math.random()}` : '0'} radius={100} imageSrc="/images/inhale.png" />
                        </div>
                    </div>
                }

                {currentContent?.type === 'video' &&
                    <video ref={videoPlayerRef} width="100%" className='p-6' loop >
                        <source src={currentContent.source} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                }
            </div>

            <div className='bg-white rounded-t-md w-full px-4' style={{ height: '40%' }} >

                <div className='flex flex-1 overflow-y-hidden flex-col'>
                    {data.content.map(((item, index) =>
                        <div key={item.id} className='my-1'>
                            <WalkthroughTile isHighlighted={currentIndex === index} seconds={secondsToTimestamp(item.seconds)} title={item.title} onClick={() => onSelectContent(index)} />
                        </div>
                    ))}
                </div>
                <div className='flex justify-between items-center my-4'>
                    <div className='flex' style={{ width: 150 }}>
                        <Button disabled={currentIndex === 0} onClick={() => onSelectContent(currentIndex - 1)}>Prev</Button>
                    </div>
                    <p className="text-3xl font-bold px-4 text-blue-500">{secondsToTimestamp(countdown)}</p>
                    <div style={{ width: 150 }}>
                        <Button onClick={() => onTogglePlayer(playFlag)}>{playFlag ? 'Pause' : 'Play'}</Button>
                    </div>

                </div>

                <label>Total : x{totalCount}</label>
            </div>
        </div>
    );
}

function secondsToTimestamp(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    // Pad minutes and seconds to always show two digits
    const paddedMinutes = String(minutes).padStart(2, '0');
    const paddedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${paddedMinutes}:${paddedSeconds}`;
}

interface CircularTimerProps {
    /** Duration in seconds for the timer to complete a full circle, random string attatched so it causes a rerender if content has same seconds because props wont rerender then */
    speedString: string;
    radius: number;
    imageSrc?: string
}

const CircularTimer: React.FC<CircularTimerProps> = ({ speedString, radius, imageSrc }) => {
    const circleRef = useRef<SVGCircleElement>(null);
    const speed = parseInt(speedString.split('_')[0], 10)

    const strokeWidth = 40;
    const normalizedRadius = radius - strokeWidth / 2;
    const circumference = normalizedRadius * 2 * Math.PI;

    useEffect(() => {
        const circle = circleRef.current;
        if (circle) {
            // Reset styles before starting the new animation
            circle.style.transition = 'none';
            circle.style.strokeDasharray = `${circumference} ${circumference}`;
            circle.style.strokeDashoffset = `${circumference}`;
            if (speed !== 0) {

                // Trigger a reflow to ensure the style changes take effect
                circle.getBoundingClientRect();

                // Start the animation
                circle.style.transition = `stroke-dashoffset ${speed}s linear`;
                circle.style.strokeDashoffset = '0';
            }
        }
    }, [circumference, speed]);

    return (
        <div className='items-center justify-center flex'>
            <img className='absolute z-10 m-auto' style={{ width: radius }} src={imageSrc} alt='Hold Breath' />

            <svg
                height={radius * 2}
                width={radius * 2}
                className="transform -rotate-90"
            >
                {/* Background Circle */}
                <circle
                    stroke="#e6e6e6"
                    fill="transparent"
                    strokeWidth={strokeWidth}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                />
                {/* Animated Circle */}
                <circle
                    ref={circleRef}
                    stroke="#3498db"
                    fill="transparent"
                    strokeWidth={strokeWidth}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                    className="transition-all duration-1000 ease-linear"
                />
            </svg>
        </div>
    );
};

export default CircularTimer;