import React, { useEffect } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext.tsx';
import { setUserDataHasSubscribed } from '../../../queries/users.query.ts';
import { routeNames } from '../../../navigation/types.navigation.ts';

function OfferCompletePage() {
    const { userData, user, fetchUserData } = useAuth();

    useEffect(() => {
        if (userData && !userData?.hasSubscribed) {
            setUserDataHasSubscribed(user?.uid || '', 'early_access_v1').then(
                () => fetchUserData()
            );
        }
    }, [userData]);
    return (
        <div className="flex flex-col items-center m-8">
            <div className="flex justify-center">
                <CheckCircleFilled className="text-primary text-8xl m-auto" />
            </div>
            <h1 className="pt-8">Thank you!</h1>
            <h3 className="pt-4">
                An email confirmation has been sent to you.
            </h3>
            <p className="pt-4">
                You now have life time access to Nook. As an early supporter we
                are very greatful and you are helping us build a mental health
                services for all mothers.
            </p>
            <Link className="pt-4 text-xl router-link" to={routeNames.nook}>
                Return to Home
            </Link>
        </div>
    );
}

export default OfferCompletePage;
