/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FirebaseError } from 'firebase/app';
import { useAuth } from '../../contexts/AuthContext.tsx';
import { routeNames } from '../../navigation/types.navigation.ts';
import { Button, Input, Page } from '../../components/common.tsx';
// import GoogleButton from '../../components/GoogleSignInButton.tsx';
import LoadingOverlay from '../../components/LoadingOverlay.tsx';
import { toLoginLinkHandler } from './ForgotPasswordPage.tsx';

const disneyPrincesses = [
    'Snow',
    'Cinderella',
    'Aurora',
    'Ariel',
    'Belle',
    'Jasmine',
    'Pocahontas',
    'Mulan',
    'Tiana',
    'Rapunzel',
    'Merida',
    'Anna',
    'Elsa',
    'Moana',
    'Raya',
];

const emails = [
    'hot_moms_hit_curbs@gmail.com',
    'imma_cool_mom@gmail.com',
    'caffiene_machine_247@gmail.com',
];

function handleFirebaseSignupError(error: FirebaseError): string {
    switch (error.code) {
        case 'auth/email-already-in-use':
            return 'This email is already associated with an account. Please try logging in or using a different email.';

        case 'auth/invalid-email':
            return 'The email address is not valid. Please check and enter a valid email.';

        case 'auth/operation-not-allowed':
            return 'Email/password sign-up is currently disabled. Please contact support.';

        case 'auth/weak-password':
            return 'Your password is too weak. Please choose a stronger password.';

        case 'auth/network-request-failed':
            return 'Network error. Please check your internet connection and try again.';

        case 'auth/too-many-requests':
            return 'Too many attempts. Please wait a moment before trying again.';

        default:
            return 'An unknown error occurred. Please try again later.';
    }
}

function SignupPage() {
    const [name, setName] = useState('');
    const [searchParams] = useSearchParams();
    const [email, setEmail] = useState(searchParams.get('email') || '');
    const [password, setPassword] = useState('');
    const { signup } = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const [page, setPage] = useState<number>(1);
    const isMobilview = window.innerWidth < 768;
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [newsletter, setNewsletter] = useState(false);
    useEffect(() => {
        // Get the 'subscription' query parameter from the URL
        const subscription = searchParams.get('subscription');
        if (subscription) {
            // Store the subscription code in localStorage or context for later use
            localStorage.setItem('subscriptionCode', subscription);
        }
    }, [searchParams]);

    const handleSubmit = async (e: React.FormEvent) => {
        if (!loading) {
            setLoading(true);
            e.preventDefault();
            try {
                await signup(name, email, password, newsletter);
                navigate(routeNames.onboard);
            } catch (err) {
                setError(handleFirebaseSignupError(err as FirebaseError));
            }
            setLoading(false);
        }
    };

    const nameIndex = useRef(
        Math.floor(Math.random() * disneyPrincesses.length)
    );

    const emailIndex = useRef(Math.floor(Math.random() * emails.length));

    if (!isMobilview) {
        return (
            <div className="flex w-full h-full">
                <div className="w-4/12 text-center m-auto">
                    {error && (
                        <p className="text-red-600 text-lg px-2">{error}</p>
                    )}
                    <h2 className="text-3xl mb-6">Welcome to Nook</h2>
                    <form
                        className="text-left w-9/12 mx-auto"
                        onSubmit={handleSubmit}
                    >
                        <div>
                            <label htmlFor="name" className="text-gray-500 ">
                                Name
                            </label>
                            <br />
                            <input
                                id="name"
                                required
                                className="rounded-lg border border-gray-400 p-2 w-full mt-1 bg-gray-50"
                                type="text"
                                placeholder={
                                    disneyPrincesses[nameIndex.current]
                                }
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                autoComplete="name"
                            />
                        </div>

                        <div className="mt-2">
                            <label htmlFor="email" className="text-gray-500 ">
                                Email
                            </label>
                            <br />
                            <input
                                data-testid="signup-email-id"
                                id="email"
                                required
                                className="rounded-lg border border-gray-400 p-2 w-full mt-1 bg-gray-50"
                                type="email"
                                placeholder={emails[emailIndex.current]}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete="email"
                                inputMode="email"
                            />
                        </div>

                        <div className="mt-2">
                            <label
                                htmlFor="password"
                                className="text-gray-500 "
                            >
                                Password
                            </label>
                            <br />
                            <input
                                id="password"
                                required
                                className="rounded-lg border border-gray-400 p-2 w-full mt-1 bg-gray-50"
                                type="password"
                                placeholder="******"
                                value={password}
                                autoComplete="new-password"
                                onChange={(e) => setPassword(e.target.value)}
                                minLength={6}
                            />
                        </div>
                        <p className="text-center text-gray-400 font-sans text-sm pt-2 pb-4">
                            By signing up, you confirm that you accept our{' '}
                            <Link
                                to="https://nooknook.au/terms"
                                className="text-button-blue underline font-bold"
                            >
                                Terms of Use
                            </Link>{' '}
                            and{' '}
                            <Link
                                to="https://nooknook.au/privacy"
                                className="text-button-blue underline font-bold"
                            >
                                Privacy Policy
                            </Link>
                        </p>
                        <Button disabled={loading} type="submit">
                            {loading ? 'Loading...' : 'Signup'}
                        </Button>

                        <p className="text-center    pt-4 ">
                            Already have an account?{' '}
                            <Link
                                to={toLoginLinkHandler(email)}
                                className="text-button-blue underline font-bold"
                            >
                                Login here
                            </Link>
                        </p>
                    </form>
                </div>
                <div
                    className="w-8/12 p-20 h-full "
                    style={{ backgroundColor: '#8369FF' }}
                >
                    <img
                        src="/images/abstract_bg.png"
                        alt="Background"
                        className="h-5/6"
                    />
                    <div>
                        <h1 className="text-6xl text-white pb-4">
                            Start Today for $0
                        </h1>
                        <h2 className="pb-4 text-white">
                            Unlock Full Access to Nook
                        </h2>
                        <h3 className="text-white">
                            Enjoy a 3-day free trial, then receive life time
                            access for $4.99
                        </h3>
                    </div>
                </div>
            </div>
        );
    }

    return page === 1 ? (
        <div className="p-3 h-full" style={{ backgroundColor: '#8369FF' }}>
            <img
                src="/images/abstract_bg.png"
                alt="Background"
                className="max-w-80 mx-auto"
            />
            <div>
                <h1 className="text-6xl text-white pb-4">Start Today for $0</h1>
                <h2 className="pb-4 text-white">Unlock Full Access to Nook</h2>
                <h3 className="text-white">
                    Enjoy a 3-day free trial, then receive life time access for
                    $4.99
                </h3>
                <div className="pt-4">
                    <Button onClick={() => setPage(2)}>Next</Button>
                </div>
            </div>
        </div>
    ) : (
        <div className=" h-full flex ">
            <div className="m-auto text-center ">
                {error && <p className="text-red-600 text-lg px-2">{error}</p>}
                <h2 className="text-3xl mb-6">Welcome to Nook</h2>
                <form
                    className="text-left w-9/12 mx-auto"
                    onSubmit={handleSubmit}
                >
                    <div>
                        <label htmlFor="name" className="text-gray-500 ">
                            Name
                        </label>
                        <br />
                        <input
                            id="name"
                            required
                            className="rounded-lg border border-gray-400 p-2 w-full mt-1 bg-gray-50"
                            type="text"
                            placeholder={disneyPrincesses[nameIndex.current]}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            autoComplete="name"
                        />
                    </div>

                    <div className="mt-2">
                        <label htmlFor="email" className="text-gray-500 ">
                            Email
                        </label>
                        <br />
                        <input
                            id="email"
                            required
                            className="rounded-lg border border-gray-400 p-2 w-full mt-1 bg-gray-50"
                            type="email"
                            placeholder={emails[emailIndex.current]}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="email"
                        />
                    </div>

                    <div className="mt-2">
                        <label htmlFor="password" className="text-gray-500 ">
                            Password
                        </label>
                        <br />
                        <input
                            id="password"
                            required
                            className="rounded-lg border border-gray-400 p-2 w-full mt-1 bg-gray-50"
                            type="password"
                            placeholder="******"
                            value={password}
                            autoComplete="new-password"
                            onChange={(e) => setPassword(e.target.value)}
                            minLength={6}
                        />
                    </div>
                    <p className="text-center text-gray-400 font-sans text-sm pt-2 pb-4">
                        By signing up, you confirm that you accept our{' '}
                        <Link
                            to="https://nooknook.au/terms"
                            className="text-button-blue underline font-bold"
                        >
                            Terms of Use
                        </Link>{' '}
                        and{' '}
                        <Link
                            to="https://nooknook.au/privacy"
                            className="text-button-blue underline font-bold"
                        >
                            Privacy Policy
                        </Link>
                    </p>

                    <Button disabled={loading} type="submit">
                        {loading ? 'Loading...' : 'Signup'}
                    </Button>

                    <p className="text-center    pt-4 ">
                        Already have an account?{' '}
                        <Link
                            to={toLoginLinkHandler(email)}
                            className="text-button-blue underline font-bold"
                        >
                            Login here
                        </Link>
                    </p>
                </form>
                <LoadingOverlay isLoading={loading} />
            </div>
        </div>
    );
}

export default SignupPage;
