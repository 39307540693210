/* eslint-disable prettier/prettier */
import React, { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown, faBrush } from '@fortawesome/free-solid-svg-icons';
import { captureException, withScope } from '@sentry/react';
import { StarOutlined } from '@ant-design/icons';
import { useAuth } from '../../contexts/AuthContext.tsx';

import { Page } from '../../components/common.tsx';
import { capitalizeFirstLetter } from '../../utils/string.helpers.ts';
import { onToContent } from '../../utils/navigate.helpers.ts';
import { Content } from '../../content/types.content.ts';
import {
    getAllContents,
    getRandomContentByInterestIds,
} from '../../content/contents.query.content.ts';
import { ModalType, useModal } from '../../contexts/ModalContext.tsx';
import { PageNotification } from '../../components/PageNotification/PageNotification.tsx';
import { routeNames } from '../../navigation/types.navigation.ts';
// import { routeNames } from '../../navigation/types.navigation.ts';

export function NookContentTitle(props: { content: Content }) {
    const { showModal } = useModal();
    const { userData } = useAuth();
    const { content } = props;
    const isUserPremium = userData?.subscriptionDetails.status === 'active';
    return (
        <Link
            to={onToContent(content.id)}
            onClick={(e) => {
                if (!isUserPremium && content.is_premium) {
                    e.preventDefault();
                    showModal(ModalType.GET_PREMIUM);
                }
            }}
        >
            <div className="flex justify-between p-2 bg-gray-100  rounded">
                <div className="flex items-center">
                    <div
                        className="h-16 w-16 overflow-hidden mr-2 rounded p-2"
                        style={{
                            backgroundColor: content.color,
                        }}
                    >
                        <img alt="" src={content.image} />
                    </div>
                    <div className="flex flex-col justify-center">
                        <p className="font-semibold text-xs text-gray-400">
                            {capitalizeFirstLetter(content.type)}
                        </p>
                        <label className="text-md flex items-baseline">
                            {content.title}
                        </label>
                        <p className="font-semibold text-xs text-gray-400">
                            {content.minutes} mins
                        </p>
                    </div>
                </div>
                <div className="self-start">
                    {content.is_premium && (
                        <div className=" bg-white rounded-full h-8 w-8 flex items-center justify-center">
                            <FontAwesomeIcon
                                icon={faCrown}
                                className="text-yellow-500"
                            />
                        </div>
                    )}
                    {/* {content.is_premium ? (
                        <FontAwesomeIcon
                            icon={faCrown}
                            className=" text-yellow-500"
                        />
                    ) : null} */}
                </div>
            </div>
        </Link>
    );
}

function TrialPromoNotificaiton() {
    const { userData, user } = useAuth();

    return (
        <PageNotification
            key="TrialPromoNotificaiton"
            notificationColor="#8369FF"
            mainText="Life Time Access $5 Special"
            secondaryText="Only avaiable during your trial period"
            icon={<StarOutlined className="text-white text-xl" />}
        >
            <p className="text-white font-bold pt-2 pb-2">
                Trial ends{' '}
                {new Date(userData?.trialEndDate || new Date()).toDateString()}
            </p>
            <Link
                // to={routeNames.offer}
                to={`${process.env.REACT_APP_PAYMENT_LINK}?utm_source=${user?.uid}&prefilled_email=${user?.email}`}
                className="underline font-bold "
                style={{
                    color: '#fff',
                }}
            >
                Click Here to Purchase
            </Link>
        </PageNotification>
    );
}

function PersonaliseNookNotification() {
    return (
        <PageNotification
            key="PersonaliseNookNotification"
            notificationColor="orange"
            secondaryText="Currently Content is random"
            icon={
                <FontAwesomeIcon
                    icon={faBrush}
                    className="text-white text-xl"
                />
            }
        >
            <Link
                // to={routeNames.offer}
                to={routeNames.account_interests}
                className="underline font-bold "
                style={{
                    color: '#fff',
                }}
            >
                Click Here to Personalise
            </Link>
        </PageNotification>
    );
}

function NotificaitonSection() {
    const { userData } = useAuth();
    const notifications: ReactNode[] = [];
    if (
        !userData?.hasSubscribed &&
        userData?.trialEndDate &&
        userData?.trialEndDate > new Date().getTime()
    ) {
        notifications.push(<TrialPromoNotificaiton />);
    }

    if (userData?.interests.length === 0) {
        notifications.push(<PersonaliseNookNotification key="1" />);
    }

    return notifications.length > 0 ? (
        <div className="pb-4">{notifications}</div>
    ) : null;
}

function NookPage() {
    const { userData } = useAuth();
    const [content, setContent] = useState<Content[]>();

    useEffect(() => {
        async function setup() {
            try {
                if (userData?.interests.length > 0) {
                    const newContent = await getRandomContentByInterestIds(
                        userData.interests
                    );
                    setContent(newContent);
                } else {
                    const newContent = await getAllContents(true, 5);
                    setContent(newContent);
                }
            } catch (e) {
                withScope((scope) => {
                    scope.setExtra('uid', userData?.id);
                    scope.setExtra('action', 'load_content_on_nook_page');
                    captureException(e);
                });
            }
        }
        setup();
    }, [userData]);

    return (
        <Page>
            <NotificaitonSection />
            <h2 className="pb-2">Relax.</h2>
            <div>
                {content?.map((item) => (
                    <div key={item.id} className="pb-2">
                        <NookContentTitle content={item} />
                    </div>
                ))}
            </div>
        </Page>
    );
}

export default NookPage;
