import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { captureException, withScope } from '@sentry/react';
import onboardPic from '../../../assets/onboard_explore.png';
import contentOnboardImage from '../../../assets/onboardPage.png';
import playOnboardImage from '../../../assets/play_onboard.png';
import premiumOnboardImage from '../../../assets/premium_onboard.png';

import OnboardTemplatePage, {
    OnboardTemplatePageProps,
} from './OnboardTemplatePage.tsx';
import { routeNames } from '../../../navigation/types.navigation.ts';
import { useAuth } from '../../../contexts/AuthContext.tsx';
import LoadingOverlay from '../../../components/LoadingOverlay.tsx';

const onboardingPages: Omit<OnboardTemplatePageProps, 'counter' | 'onNext'>[] =
    [
        {
            header: 'Explore',
            description:
                'Nook has many topics that will help you throughout the day having you feeling positive,relaxed and ready',
            imageSrc: onboardPic,
        },
        {
            header: 'Content',
            description: `Nook's content provides a supportive way for mums to reconnect with themselves. Each session combines calming narration, soothing music, and simple breathing techniques to reduce stress, improve sleep, and boost emotional well-being.`,
            imageSrc: contentOnboardImage,
        },
        {
            header: 'Listen',
            description:
                'Listening to these meditations is effortless—simply press play and immerse yourself in soothing narration and gentle music. With just a few moments each day, you’ll find relaxation, clarity, and a peaceful respite from motherhood’s demands.',
            imageSrc: playOnboardImage,
        },
        {
            header: 'Try Nook Premium',
            description:
                'For 3 days you will have access to all the content on Nook, No Payment Details Needed. Enjoy!',
            imageSrc: premiumOnboardImage,
            buttonLabel: 'Start',
        },
    ];

function OnboardingShell() {
    const pagerRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const { user, completeOnboardHandler } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    async function onCompleteHandler() {
        try {
            setIsLoading(true);
            await completeOnboardHandler();
            navigate(routeNames.nook);
        } catch (e) {
            withScope((scope) => {
                scope.setExtra('uid', user?.uid);
                scope.setExtra('action', 'update_user_obnboard_flag');
                captureException(e);
            });
        }
        setIsLoading(false);
    }

    const onNextHandler = (nextPage: number, isComplete: boolean) => {
        if (isComplete) {
            navigate(routeNames.nook);
        }
        pagerRef.current?.scrollTo({
            left: nextPage * window.screen.width,
            behavior: 'smooth',
        });
    };

    return (
        <div
            ref={pagerRef}
            className="overflow-x-scroll flex flex-nowrap h-full"
        >
            <LoadingOverlay isLoading={isLoading} />
            {onboardingPages.map((pageInfo, index) => (
                <OnboardTemplatePage
                    key={pageInfo.header}
                    {...pageInfo}
                    onNext={() =>
                        index === onboardingPages.length - 1
                            ? onCompleteHandler()
                            : onNextHandler(
                                  index + 1,
                                  index === onboardingPages.length - 1
                              )
                    }
                    counter={
                        <div>
                            {index + 1}/{onboardingPages.length}
                        </div>
                    }
                />
            ))}
        </div>
    );
}

export default OnboardingShell;
