import React from 'react';

import { useGetAllInterests } from '../../content/interests.query.content.ts';

function Pill(props: {
    title: string;
    isSelected: boolean;
    onClick: () => void;
}) {
    const { isSelected, onClick, title } = props;
    return (
        <button
            type="button"
            onClick={onClick}
            className={` rounded-full w-full  ${isSelected ? 'text-white bg-blue-400' : 'text-blue-400 bg-white'} border-blue-400 border font-bold text-lg text-left px-3 py-1 md:px-5 md:py-2`}
        >
            {title}
        </button>
    );
}

export function InterestList(props: {
    selectedInterests: number[];
    setSelectedInterests: React.Dispatch<React.SetStateAction<number[]>>;
}) {
    const { selectedInterests, setSelectedInterests } = props;

    const { data: interests } = useGetAllInterests();
    async function onToggleInterestHandler(id: number) {
        if (selectedInterests.includes(id)) {
            const newSelectedInterests = selectedInterests.filter(
                (item) => item !== id
            );
            setSelectedInterests(newSelectedInterests);
        } else {
            const newSelectedInterests = [...selectedInterests, id];
            setSelectedInterests(newSelectedInterests);
        }
    }

    return (
        <div className="flex flex-wrap w-full">
            {interests.map((item) => (
                <div className="pb-2 pr-4">
                    <Pill
                        isSelected={selectedInterests.includes(item.id)}
                        title={item.title}
                        onClick={() => onToggleInterestHandler(item.id)}
                    />
                </div>
            ))}
        </div>
    );
}
